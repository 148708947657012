import React from 'react';
import Slider from 'react-slick';
import { Box } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface ImageGalleryProps {
  images: string[];
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ images }) => {
  if (!images || images.length === 0) return null;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Slider {...settings}>
        {images.map((img, idx) => (
          <Box key={idx}>
            <img src={img} alt={`Event ${idx}`} style={{ width: '100%', maxHeight: '500px', objectFit: 'cover' }} />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default ImageGallery;
