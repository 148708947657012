import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Snackbar,
  Alert,
  SnackbarProps,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { BOOK_EVENT_MUTATION } from '../../graphql/events';
import { Event, EventBooking } from '../../generated/graphql';

interface RequestEventBookingModalProps {
  open: boolean;
  onClose: () => void;
  event: Event;
}

const RequestEventBookingModal: React.FC<RequestEventBookingModalProps> = ({ open, onClose, event }) => {
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMsg, setSnackbarMsg] = React.useState('');
  // Define severity state: "success" for green, "error" for red.
  const [snackbarSeverity, setSnackbarSeverity] = React.useState<'success' | 'error'>('success');

  const [bookEvent, { loading, error }] = useMutation<{ bookEvent: EventBooking }, { eventId: string }>(
    BOOK_EVENT_MUTATION
  );

  // onClose handler for the Snackbar with the correct type.
  const handleSnackbarClose: SnackbarProps['onClose'] = (_event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  const handleConfirm = async () => {
    try {
      await bookEvent({ variables: { eventId: event.id } });
      setSnackbarMsg('Booking request successful!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (e: any) {
      console.error('Booking event failed', e);
      setSnackbarMsg(e.message || 'Booking request failed');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
    // Delay closing the modal so that the Snackbar is visible.
    setTimeout(() => {
      onClose();
    }, 3000);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Request Booking</DialogTitle>
        <DialogContent>
          <Typography>
            Do you want to request a booking for the event <strong>{event.title}</strong>?
          </Typography>
          {error && (
            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
              {error.message}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary" disabled={loading}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} variant="contained" color="primary" disabled={loading}>
            {loading ? 'Processing...' : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={(e) => handleSnackbarClose(e, 'timeout')} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RequestEventBookingModal;
