import React from 'react';
import { Container, CircularProgress, Alert } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { EVENT_QUERY } from '../../graphql/events';
import EventDetail from '../../components/Events/EventDetail';

const EventDetailPage: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const { data, loading, error } = useQuery(EVENT_QUERY, { variables: { id: eventId } });

  if (loading) {
    return (
      <Container sx={{ textAlign: 'center', mt: 4 }}>
        <CircularProgress />
      </Container>
    );
  }
  if (error || !data?.event) {
    return (
      <Container sx={{ mt: 4 }}>
        <Alert severity="error">Error loading event details.</Alert>
      </Container>
    );
  }
  return <EventDetail event={data.event} />;
};

export default EventDetailPage;
