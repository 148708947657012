import React, { useState } from 'react';
import { Paper, Typography, Button, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { Event, Role } from '../../generated/graphql';
import { useAuth } from '../../contexts/AuthContext';
import RequestEventBookingModal from './RequestEventBookingModal';
import { useTranslation } from 'react-i18next';

const BookNowSectionEvent: React.FC<{ event: Event }> = ({ event }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState(false);

  // Determine if the event has already started
  const eventHasStarted = DateTime.now() >= DateTime.fromJSDate(new Date(event.startDate));

  // Calculate confirmed bookings (assumes event.bookings exists and has a status field)
  const confirmedBookings = event.totalBookings;
  const isEventFull = confirmedBookings >= event.capacity;

  const userIsMember = user && user.roles?.includes(Role.Member);

  const handleRequestBooking = () => {
    if (!eventHasStarted) {
      setModalOpen(true);
    }
  };

  const occupancyText = `${t('occupancy')}: ${confirmedBookings}/${event.capacity}`;

  return (
    <Paper
      sx={{
        p: 3,
        mt: 4,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 2,
        boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
      }}
    >
      <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>
        {t('ready_to_book')}
      </Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        Currently we only accept booking, the payment will be done on the arrival in cash.
      </Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        {eventHasStarted
          ? t('event_already_started')
          : !user
            ? t('log_in_or_sign_up_to_book')
            : !userIsMember
              ? t('become_a_member_to_book')
              : t('book_this_event')}
      </Typography>
      {occupancyText && (
        <Typography variant="body2" sx={{ mb: 2, fontWeight: 500 }}>
          {occupancyText}
        </Typography>
      )}
      {!user || !userIsMember ? (
        <Button
          variant="contained"
          color="primary"
          href={user ? '/membership-dashboard' : '/auth'}
          sx={{ textTransform: 'none' }}
        >
          {user ? t('become_a_member') : t('log_in_or_sign_up')}
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={handleRequestBooking}
          disabled={isEventFull || eventHasStarted}
          sx={{ textTransform: 'none' }}
        >
          {isEventFull ? t('event_full') : t('book_now')}
        </Button>
      )}
      {modalOpen && <RequestEventBookingModal open={modalOpen} onClose={() => setModalOpen(false)} event={event} />}
    </Paper>
  );
};

export default BookNowSectionEvent;
