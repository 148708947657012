import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { PRIVATE_EVENTS_QUERY, PUBLISH_EVENT, CANCEL_EVENT_BY_ORGANIZER } from '../../graphql/events';
import {
  Event,
  EventOrderField,
  EventStatusEnum,
  OrderDirection,
  PrivateEventsQuery,
  PrivateEventsQueryVariables,
  PublishEventMutation,
  PublishEventMutationVariables,
  CancelEventByOrganizerMutation,
  CancelEventByOrganizerMutationVariables,
  Role,
} from '../../generated/graphql';
import { useAuth } from '../../contexts/AuthContext';
import { isAdminGuard } from '../../utils/isAdminGuard';

const OrganizerEventManagementPage: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const { data, loading, error, fetchMore, refetch } = useQuery<PrivateEventsQuery, PrivateEventsQueryVariables>(
    PRIVATE_EVENTS_QUERY,
    {
      variables: {
        filter: {},
        first: 10,
        orderBy: {
          field: EventOrderField.StartDate,
          direction: OrderDirection.Asc,
        },
      },
      fetchPolicy: 'network-only',
    }
  );

  const events: Event[] = data?.privateEvents?.edges?.map((edge) => edge.node as Event) || [];
  const pageInfo = data?.privateEvents?.pageInfo;

  // Mutation to publish an event.
  const [publishEventMutation, { loading: publishLoading }] = useMutation<
    PublishEventMutation,
    PublishEventMutationVariables
  >(PUBLISH_EVENT, { onCompleted: () => refetch() });

  // New mutation to cancel an event by organizer.
  const [cancelEventMutation, { loading: cancelLoading }] = useMutation<
    CancelEventByOrganizerMutation,
    CancelEventByOrganizerMutationVariables
  >(CANCEL_EVENT_BY_ORGANIZER, { onCompleted: () => refetch() });

  // Local state for the cancellation dialog.
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [selectedEventForCancel, setSelectedEventForCancel] = useState<Event | null>(null);
  const [cancelReason, setCancelReason] = useState('');

  // Handler: Publish an event.
  const handlePublish = async (eventId: string) => {
    try {
      await publishEventMutation({ variables: { eventId } });
    } catch (err) {
      console.error('Error publishing event: ', err);
    }
  };

  // Handler: Edit event.
  const handleEdit = (eventId: string) => {
    navigate(`/events/${eventId}/edit`);
  };

  // Handler: Navigate to organizer detail page.
  const handleDetail = (eventId: string) => {
    navigate(`/events/${eventId}/organizer-detail`);
  };

  // Handler: Navigate to create event page.
  const handleCreate = () => {
    navigate('/events/create');
  };

  // Handler: Open cancellation dialog.
  const handleOpenCancelDialog = (event: Event) => {
    setSelectedEventForCancel(event);
    setCancelDialogOpen(true);
  };

  // Handler: Close cancellation dialog.
  const handleCloseCancelDialog = () => {
    setCancelDialogOpen(false);
    setSelectedEventForCancel(null);
    setCancelReason('');
  };

  // Handler: Confirm cancellation (calls the mutation with cancellation reason).
  const handleConfirmCancel = async () => {
    if (!selectedEventForCancel) return;
    try {
      await cancelEventMutation({
        variables: { eventId: selectedEventForCancel.id, reason: cancelReason },
      });
      handleCloseCancelDialog();
    } catch (err) {
      console.error('Error cancelling event:', err);
    }
  };

  // Pagination: load more events if available.
  const handleLoadMore = () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: { after: pageInfo.endCursor },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prevResult;
          return {
            privateEvents: {
              __typename: prevResult.privateEvents.__typename,
              totalCount: fetchMoreResult.privateEvents.totalCount,
              edges: [...(prevResult.privateEvents.edges || []), ...(fetchMoreResult.privateEvents.edges || [])],
              pageInfo: fetchMoreResult.privateEvents.pageInfo,
            },
          };
        },
      });
    }
  };

  if (loading) {
    return (
      <Box textAlign="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Event Management Dashboard</Typography>
        <Button variant="contained" color="primary" onClick={handleCreate}>
          Create New Event
        </Button>
      </Box>

      {events.length === 0 ? (
        <Alert severity="info">No events found.</Alert>
      ) : (
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Timezone</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events.map((event) => (
                <TableRow key={event.id}>
                  <TableCell>
                    <Button variant="text" onClick={() => handleDetail(event.id)}>
                      {event.title}
                    </Button>
                  </TableCell>
                  <TableCell>
                    {DateTime.fromISO(event.startDate.toString()).toLocaleString(DateTime.DATE_MED)}
                  </TableCell>
                  <TableCell>{DateTime.fromISO(event.endDate.toString()).toLocaleString(DateTime.DATE_MED)}</TableCell>
                  <TableCell>{event.location}</TableCell>
                  <TableCell>{event.timezone || '-'}</TableCell>
                  <TableCell>{event.status || '-'}</TableCell>
                  <TableCell>
                    <Button variant="outlined" size="small" onClick={() => handleEdit(event.id)}>
                      Edit
                    </Button>
                    {event.status === EventStatusEnum.Initiated && user?.roles.includes(Role.Admin) && (
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={() => handlePublish(event.id)}
                        disabled={publishLoading}
                        sx={{ ml: 1 }}
                      >
                        Publish
                      </Button>
                    )}
                    {event.status === EventStatusEnum.Active && (
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() => handleOpenCancelDialog(event)}
                        sx={{ ml: 1 }}
                      >
                        Cancel
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}

      {pageInfo?.hasNextPage && (
        <Box textAlign="center" mt={3}>
          <Button variant="contained" onClick={handleLoadMore}>
            Load More
          </Button>
        </Box>
      )}

      <Dialog open={cancelDialogOpen} onClose={handleCloseCancelDialog} fullWidth maxWidth="sm">
        <DialogTitle>Cancel Event</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to cancel the event "{selectedEventForCancel?.title}"?</Typography>
          <TextField
            autoFocus
            margin="dense"
            label="Cancellation Reason"
            type="text"
            fullWidth
            variant="outlined"
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancelDialog}>Close</Button>
          <Button variant="contained" color="error" onClick={handleConfirmCancel} disabled={cancelLoading}>
            Confirm Cancellation
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default OrganizerEventManagementPage;
