import React, { useMemo, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Event, PublicEventsQuery, PublicEventsQueryVariables } from '../../generated/graphql';
import EventsCalendar from '../../components/Events/EventsCalendar';
import { PUBLIC_EVENTS_QUERY } from '../../graphql/events';
import { DateTime } from 'luxon';
import { Box, Button, Container, Typography, useTheme, useMediaQuery } from '@mui/material';

const EVENTS_PER_PAGE = 100;

const EventsPage: React.FC = () => {
  const theme = useTheme();

  // Unconditionally call useMediaQuery for each breakpoint.
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const md = useMediaQuery(theme.breakpoints.only('md'));
  //   const lg = useMediaQuery(theme.breakpoints.only('lg'));
  //   const xl = useMediaQuery(theme.breakpoints.only('xl'));

  // Determine number of days based on breakpoints.
  let numberOfDays: number;
  if (xs) {
    numberOfDays = 1;
  } else if (sm) {
    numberOfDays = 2;
  } else if (md) {
    numberOfDays = 3;
  } else {
    numberOfDays = 4;
  }

  // Use the start of the day so that our view always begins at midnight.
  const [currentDate, setCurrentDate] = useState<DateTime>(DateTime.now().startOf('day'));

  // Build the date filter based on the current date and the number of days.
  const dateFilter = useMemo(() => {
    const startDateFrom = currentDate.toISO();
    const endDate = currentDate.plus({ days: numberOfDays - 1 }).endOf('day');
    const startDateTo = endDate.toISO();
    return { startDateFrom, startDateTo };
  }, [currentDate, numberOfDays]);

  // Execute the GraphQL query with the dynamic filter.
  const { data, loading, error, fetchMore, refetch } = useQuery<PublicEventsQuery, PublicEventsQueryVariables>(
    PUBLIC_EVENTS_QUERY,
    {
      variables: {
        filter: dateFilter,
        first: EVENTS_PER_PAGE,
      },
      fetchPolicy: 'network-only',
    }
  );

  // Maintain a list of events.
  const [allEvents, setAllEvents] = useState<Event[]>([]);
  useEffect(() => {
    if (data && data.publicEvents) {
      const newEvents = data.publicEvents.edges?.map((edge) => edge.node as Event) || [];
      setAllEvents(newEvents);
    }
  }, [data]);

  // When the date range changes, re‑fetch events and reset the event list.
  useEffect(() => {
    refetch({
      filter: dateFilter,
      first: EVENTS_PER_PAGE,
    }).then(({ data }) => {
      if (data && data.publicEvents) {
        const newEvents = data.publicEvents.edges?.map((edge) => edge.node as Event) || [];
        setAllEvents(newEvents);
      }
    });
  }, [currentDate, dateFilter, refetch]);

  // Navigation: update the current date by the number of days visible.
  const onNavigate = (direction: 'prev' | 'next' | 'today') => {
    if (direction === 'prev') {
      setCurrentDate(currentDate.minus({ days: numberOfDays }));
    } else if (direction === 'next') {
      setCurrentDate(currentDate.plus({ days: numberOfDays }));
    } else if (direction === 'today') {
      setCurrentDate(DateTime.now().startOf('day'));
    }
  };

  // Pagination: load more events if available.
  const loadMore = () => {
    if (data && data.publicEvents && data.publicEvents.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          after: data.publicEvents.pageInfo.endCursor,
          filter: dateFilter,
          first: EVENTS_PER_PAGE,
        },
      }).then((fetchResult) => {
        const moreEvents = fetchResult.data.publicEvents?.edges?.map((edge) => edge.node as Event) || [];
        setAllEvents((prev) => [...prev, ...moreEvents]);
      });
    }
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      {error && (
        <Typography variant="h6" color="error" align="center" sx={{ mb: 2 }}>
          Error loading events. Please try again.
        </Typography>
      )}

      <EventsCalendar
        events={allEvents}
        isLoading={loading}
        currentDate={currentDate}
        numberOfDays={numberOfDays}
        onNavigateWeek={onNavigate}
      />

      {/* Pagination / Load More */}
      {data && data.publicEvents && data.publicEvents.pageInfo.hasNextPage && (
        <Box textAlign="center" mt={4}>
          <Button variant="contained" onClick={loadMore}>
            Load More Events
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default EventsPage;
