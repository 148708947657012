import React from 'react';
import { useField } from 'formik';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { TextFieldProps } from '@mui/material';
import { DateTime } from 'luxon';

interface FormikDateTimePickerProps
  extends Omit<React.ComponentProps<typeof DateTimePicker>, 'value' | 'onChange' | 'renderInput'> {
  name: string;
  label: string;
  /**
   * Custom input format – default is "dd.MM.yyyy HH:mm" for European 24‑hour display.
   */
  inputFormat?: string;
  /**
   * Locale for the adapter (default "en-GB" for European formatting).
   */
  adapterLocale?: string;
}

const FormikDateTimePicker: React.FC<FormikDateTimePickerProps> = ({
  name,
  label,
  inputFormat = 'dd.MM.yyyy HH:mm',
  adapterLocale = 'en-GB',
  ...otherProps
}) => {
  // Connect the field to Formik.
  // The field's value is expected to be either a Date, a number (timestamp), or null.
  const [field, meta, helpers] = useField<Date | number | null>(name);

  // Convert the Formik value to a Luxon DateTime instance.
  let pickerValue: DateTime | null = null;
  if (field.value) {
    if (typeof field.value === 'number') {
      pickerValue = DateTime.fromMillis(field.value);
    } else if (field.value instanceof Date) {
      pickerValue = DateTime.fromJSDate(field.value);
    } else {
      // If for some reason the value is not a number or Date, try converting it.
      pickerValue = DateTime.fromJSDate(new Date(field.value));
    }
  }

  // Set up error properties for the underlying TextField.
  const textFieldProps: Partial<TextFieldProps> = {};
  if (meta.touched && meta.error) {
    textFieldProps.error = true;
    textFieldProps.helperText = meta.error as string;
  }

  // Cast the DateTimePicker to a generic component type (any) so we can pass our extra prop (inputFormat).
  const Picker: React.ComponentType<any> = DateTimePicker;

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={adapterLocale}>
      <Picker
        label={label}
        value={pickerValue}
        onChange={(newValue: DateTime | null) => {
          if (newValue && newValue.isValid) {
            // Here, we choose to store the value as a native Date object.
            helpers.setValue(newValue.toJSDate());
          } else {
            helpers.setValue(null);
          }
        }}
        ampm={false} // Use 24-hour time (no AM/PM)
        inputFormat={inputFormat} // Custom input format for European style
        slotProps={{
          textField: {
            fullWidth: true,
            ...textFieldProps,
          },
        }}
        {...otherProps}
      />
    </LocalizationProvider>
  );
};

export default FormikDateTimePicker;
