import { gql } from '@apollo/client';

export const EVENT_DATA_FRAGMENT = gql`
  fragment EventData on Event {
    id
    title
    description
    location
    startDate
    endDate
    capacity
    price
    images
    staff {
      id
      firstname
      lastname
      imageUrl
      email
    }
    organizer {
      id
      firstname
      lastname
      imageUrl
      email
    }
    createdAt
    updatedAt
    tags
    totalBookings
    country
    state
    city
    zipCode
    streetAddress
    timezone
    arrivalLocation
    howToGetThere
    status
  }
`;

export const CREATE_EVENT_MUTATION = gql`
  mutation CreateEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      ...EventData
    }
  }
  ${EVENT_DATA_FRAGMENT}
`;

export const UPDATE_EVENT_MUTATION = gql`
  mutation UpdateEvent($id: String!, $input: UpdateEventInput!) {
    updateEvent(id: $id, input: $input) {
      ...EventData
    }
  }
  ${EVENT_DATA_FRAGMENT}
`;

export const EVENT_QUERY = gql`
  query Event($id: String!) {
    event(id: $id) {
      ...EventData
    }
  }
  ${EVENT_DATA_FRAGMENT}
`;

export const PUBLIC_EVENTS_QUERY = gql`
  query PublicEvents(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filter: SearchEventFilter
    $orderBy: EventOrder
    $skip: Int
  ) {
    publicEvents(
      after: $after
      before: $before
      first: $first
      last: $last
      filter: $filter
      orderBy: $orderBy
      skip: $skip
    ) {
      totalCount
      edges {
        cursor
        node {
          ...EventData
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${EVENT_DATA_FRAGMENT}
`;

export const PRIVATE_EVENTS_QUERY = gql`
  query PrivateEvents(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filter: SearchEventFilter
    $orderBy: EventOrder
    $skip: Int
  ) {
    privateEvents(
      after: $after
      before: $before
      first: $first
      last: $last
      filter: $filter
      orderBy: $orderBy
      skip: $skip
    ) {
      totalCount
      edges {
        cursor
        node {
          ...EventData
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${EVENT_DATA_FRAGMENT}
`;

export const EVENT_BOOKING_DATA_FRAGMENT = gql`
  fragment EventBookingData on EventBooking {
    id
    createdAt
    updatedAt
    status
    userId
    user {
      id
      email
      firstname
      lastname
    }
    event {
      ...EventData
    }
  }
  ${EVENT_DATA_FRAGMENT}
`;

export const BOOK_EVENT_MUTATION = gql`
  mutation BookEvent($eventId: String!) {
    bookEvent(eventId: $eventId) {
      id
      eventId
      userId
      status
      createdAt
      updatedAt
    }
  }
`;

export const PUBLISH_EVENT = gql`
  mutation PublishEvent($eventId: String!) {
    publishEvent(eventId: $eventId) {
      id
      status
    }
  }
`;

export const MY_EVENTS_BOOKINGS = gql`
  query MyEventBookings($after: String, $before: String, $first: Int, $last: Int) {
    myEventBookings(after: $after, before: $before, first: $first, last: $last) {
      totalCount
      edges {
        cursor
        node {
          id
          createdAt
          updatedAt
          status
          event {
            ...EventData
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${EVENT_DATA_FRAGMENT}
`;

export const CANCEL_MY_EVENT_MUTATION = gql`
  mutation CancelMyEvent($eventId: String!) {
    cancelMyEvent(eventId: $eventId) {
      id
      status
    }
  }
`;

export const ORGANIZER_EVENT_BOOKINGS_QUERY = gql`
  query OrganizerEventBookings(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $skip: Int
    $eventId: String!
  ) {
    organizerEventBookings(eventId: $eventId, after: $after, before: $before, first: $first, last: $last, skip: $skip) {
      totalCount
      edges {
        cursor
        node {
          ...EventBookingData
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${EVENT_BOOKING_DATA_FRAGMENT}
`;

export const ORGANIZER_UPDATE_BOOKING_STATUS_MUTATION = gql`
  mutation OrganizerUpdateBookingStatus($bookingId: String!, $status: EventBookingStatusEnum!) {
    organizerUpdateBookingStatus(bookingId: $bookingId, status: $status) {
      ...EventBookingData
    }
  }
  ${EVENT_BOOKING_DATA_FRAGMENT}
`;

export const CANCEL_EVENT_BY_ORGANIZER = gql`
  mutation CancelEventByOrganizer($eventId: String!, $reason: String!) {
    cancelEventByOrganizer(eventId: $eventId, reason: $reason) {
      id
      status
    }
  }
`;
