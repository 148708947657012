import { Parallax } from 'react-parallax';

import { Box, Container, Typography } from '@mui/material';
import { CustomButton } from '../../components/common/Button';
import { useTranslation } from 'react-i18next';
import LoyaltyIcon from '@mui/icons-material/Loyalty';

function ParallaxSection() {
  const { t } = useTranslation();

  return (
    <Parallax bgImage="/images/loyalty/goyamboka-beach.jpeg" strength={500}>
      <Box
        sx={{
          height: {
            xs: 300,
            sm: 400,
          },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#fff',
          textAlign: 'center',
          backgroundColor: 'rgba(0,0,0,0.5)',
          backgroundBlendMode: 'darken',
        }}
      >
        <Container>
          <LoyaltyIcon
            color="primary"
            sx={{
              fontSize: {
                xs: 40,
                sm: 60,
              },
              my: 2,
            }}
          />
          <Typography
            variant="h2"
            sx={{
              fontWeight: 'bold',
              fontSize: {
                xs: '1.75rem',
                sm: '3rem',
              },
            }}
          >
            {t('loyalty_program_title')}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              mt: 2,
              fontSize: {
                xs: '1rem',
                sm: '1.5rem',
              },
            }}
          >
            {t('loyalty_program_subtitle')}
          </Typography>
          <CustomButton
            text={t('join_now')}
            color="secondary"
            variant="contained"
            href="/membership-dashboard"
            sx={{ mt: 4 }}
          />
        </Container>
      </Box>
    </Parallax>
  );
}

export default ParallaxSection;
