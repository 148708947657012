import { Typography } from '@mui/material';
import { Card } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';

export const ExperienceCard = ({ method }: { method: { activity: string; exp: string } }) => {
  const { t } = useTranslation();
  return (
    <Card
      sx={{
        textAlign: 'center',
        padding: 3,
        backgroundColor: '#f5f5f5',
        borderRadius: '20px',
      }}
    >
      <CheckCircleIcon color="primary" sx={{ fontSize: 50, mb: 2 }} />
      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
        {t(method.activity)}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {method.exp}
      </Typography>
    </Card>
  );
};
