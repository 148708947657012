import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  useMediaQuery,
  useTheme,
  Button,
  Avatar,
  Snackbar,
  Alert,
} from '@mui/material';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { DateTime } from 'luxon';
import ImageGallery from './ImageGallery';
import { Event } from '../../generated/graphql';
import { useTranslation } from 'react-i18next';
import BookNowSectionEvent from './BookNowSectionEvent';

const EventDetail: React.FC<{ event: Event }> = ({ event }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [liked, setLiked] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState('');

  // Format dates using Luxon.
  const start = DateTime.fromJSDate(new Date(event.startDate));
  const end = DateTime.fromJSDate(new Date(event.endDate));
  const formattedDate = `${start.toFormat('dd.LL.yyyy HH:mm')} – ${end.toFormat('dd.LL.yyyy HH:mm')}`;

  // Sanitize description.
  // const sanitizedDescription = DOMPurify.sanitize(event.description || '');

  // Event URL (for share & add-to-calendar)
  const eventUrl = window.location.href;

  // Like handler
  const handleLike = () => {
    // Toggle local state (you might also call a mutation here)
    setLiked((prev) => !prev);
    setSnackbarMsg(liked ? t('unliked') : t('liked'));
    setSnackbarOpen(true);
  };

  // Share handler using Web Share API or fallback to clipboard.
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: event.title,
          text: event?.description || '',
          url: eventUrl,
        });
      } catch (err) {
        console.error('Error sharing:', err);
      }
    } else {
      try {
        await navigator.clipboard.writeText(eventUrl);
        setSnackbarMsg(t('copied_to_clipboard'));
        setSnackbarOpen(true);
      } catch (err) {
        console.error('Clipboard error:', err);
      }
    }
  };

  // Add to Calendar handler: open a Google Calendar event creation link
  const handleAddToCalendar = () => {
    // Format start and end dates as "YYYYMMDDTHHmmssZ"
    const formatForCalendar = (dt: DateTime) => dt.toUTC().toFormat("yyyyLLdd'T'HHmmss'Z'");
    const startStr = formatForCalendar(start);
    const endStr = formatForCalendar(end);
    // Construct a Google Calendar URL with pre-filled details.
    const calendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
      event.title
    )}&dates=${startStr}/${endStr}&details=${encodeURIComponent(event.description || '')}&location=${encodeURIComponent(
      event.location
    )}&sf=true&output=xml`;
    window.open(calendarUrl, '_blank');
  };

  const handleSnackbarClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: '#0B1C2D',
          color: 'white',
          py: { xs: 6, md: 10 },
          mb: 4,
        }}
      >
        <Container>
          {/* Image Gallery */}
          <Box
            sx={{
              width: { xs: '90%', md: '50%' },
              mx: 'auto',
              mb: 3,
              borderRadius: 2,
              overflow: 'hidden',
              aspectRatio: '2 / 1',
              position: 'relative',
            }}
          >
            <ImageGallery images={event.images || []} />
          </Box>

          {/* Event Title */}
          <Typography variant={isMobile ? 'h4' : 'h2'} align="center" sx={{ fontWeight: 'bold', mb: 2 }}>
            {event.title}
          </Typography>

          {/* Staff Section */}
          {event.staff && event.staff.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 3,
                flexWrap: 'wrap',
                mb: 2,
              }}
            >
              {event.staff.map((staff) => (
                <Box key={staff.id} sx={{ textAlign: 'center', mx: 2 }}>
                  <Avatar
                    src={staff.imageUrl || '/default-avatar.png'}
                    alt={`${staff.firstname} ${staff.lastname}`}
                    sx={{ width: 60, height: 60, mb: 1, mx: 'auto' }}
                  />
                  <Typography variant="caption">
                    <Link to={`/user/${staff.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                      {staff.firstname} {staff.lastname}
                    </Link>
                  </Typography>
                </Box>
              ))}
            </Box>
          )}

          {/* Date, Location, Price */}
          <Typography variant="subtitle1" align="center" sx={{ mb: 1 }}>
            {formattedDate}
          </Typography>
          <Typography variant="subtitle1" align="center" sx={{ mb: 2 }}>
            {t('location')}: {event.location}
          </Typography>
          <Typography variant="subtitle1" align="center" sx={{ mb: 2 }}>
            {t('price')}: €{event.price} {t('per_person')}
          </Typography>

          {/* Action Buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap', mt: 2 }}>
            <Button variant="contained" color="primary" sx={{ textTransform: 'none' }} onClick={handleLike}>
              {liked ? t('unlike') : t('like')}
            </Button>
            <Button variant="outlined" color="primary" sx={{ textTransform: 'none' }} onClick={handleShare}>
              {t('share')}
            </Button>
            <Button variant="outlined" color="primary" sx={{ textTransform: 'none' }} onClick={handleAddToCalendar}>
              {t('add_to_calendar')}
            </Button>
          </Box>
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Typography variant="body2" color="grey.300">
              {t('tags')}: {event.tags?.join(', ')}
            </Typography>
          </Box>
        </Container>
      </Box>

      {/* Main Content */}
      <Container maxWidth="md" sx={{ mb: 6 }}>
        <Paper sx={{ p: { xs: 3, md: 4 }, backgroundColor: '#F7F7F7' }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
            {t('popis')}
          </Typography>
          <Box
            sx={{
              typography: 'body1',
              lineHeight: 1.7,
              color: '#333',
            }}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(event.description || '') }}
          />
        </Paper>
        <Box sx={{ mt: 4 }}>
          <BookNowSectionEvent event={event} />
        </Box>
      </Container>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        // onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="info" sx={{ width: '100%' }}>
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EventDetail;
