import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useQuery, useMutation } from '@apollo/client';
import {
  EventBookingStatusEnum,
  OrganizerEventBookingsQuery,
  OrganizerEventBookingsQueryVariables,
  OrganizerUpdateBookingStatusMutation,
  OrganizerUpdateBookingStatusMutationVariables,
} from '../../generated/graphql';
import { ORGANIZER_EVENT_BOOKINGS_QUERY, ORGANIZER_UPDATE_BOOKING_STATUS_MUTATION } from '../../graphql/events';

const OrganizerEventDetailPage: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const navigate = useNavigate();

  const { data, loading, error, refetch } = useQuery<OrganizerEventBookingsQuery, OrganizerEventBookingsQueryVariables>(
    ORGANIZER_EVENT_BOOKINGS_QUERY,
    {
      variables: { eventId: eventId!, skip: 0 },
      fetchPolicy: 'network-only',
    }
  );

  const [updateBookingStatus, { loading: updateLoading }] = useMutation<
    OrganizerUpdateBookingStatusMutation,
    OrganizerUpdateBookingStatusMutationVariables
  >(ORGANIZER_UPDATE_BOOKING_STATUS_MUTATION, {
    onCompleted: () => refetch(),
  });

  const [selectedBookingId, setSelectedBookingId] = useState<string | null>(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [newStatus, setNewStatus] = useState<EventBookingStatusEnum | null>(null);

  if (loading) {
    return (
      <Box textAlign="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  // Extract bookings from query result.
  const bookings = data?.organizerEventBookings?.edges?.map((edge) => edge.node) || [];

  // Sort bookings so that finalized statuses appear first.
  const sortedBookings = [...bookings].sort((a, b) => {
    // We'll consider ATTENTED and NOT_ATTENTED as finalized statuses.
    const getPriority = (status: EventBookingStatusEnum) =>
      status === EventBookingStatusEnum.Attended || status === EventBookingStatusEnum.NotAttended ? 2 : 1;
    return getPriority(a.status) - getPriority(b.status);
  });

  const handleStatusUpdateClick = (bookingId: string, status: EventBookingStatusEnum) => {
    setSelectedBookingId(bookingId);
    setNewStatus(status);
    setConfirmDialogOpen(true);
  };

  const handleDialogClose = () => {
    setConfirmDialogOpen(false);
    setSelectedBookingId(null);
    setNewStatus(null);
  };

  const handleConfirmStatusUpdate = async () => {
    if (!selectedBookingId || !newStatus) return;
    try {
      await updateBookingStatus({
        variables: {
          bookingId: selectedBookingId,
          status: newStatus,
        },
      });
      handleDialogClose();
    } catch (err) {
      console.error('Error updating booking status:', err);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box mb={3}>
        <Button variant="text" onClick={() => navigate(-1)}>
          &larr; Back
        </Button>
      </Box>
      <Typography variant="h4" gutterBottom>
        Organizer Event Details
      </Typography>
      <Paper sx={{ mb: 3, p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Bookings for Event: {data?.organizerEventBookings?.edges?.[0]?.node?.event?.title}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User's name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedBookings.map((booking) => {
              const event = booking.event;
              const startDate = DateTime.fromISO(event?.startDate.toString()).toLocaleString(DateTime.DATE_MED);
              const endDate = DateTime.fromISO(event?.endDate.toString()).toLocaleString(DateTime.DATE_MED);
              return (
                <TableRow key={booking.id}>
                  <TableCell>{`${booking.user?.firstname} ${booking.user?.lastname}`}</TableCell>
                  <TableCell>{booking.status}</TableCell>
                  <TableCell>{startDate}</TableCell>
                  <TableCell>{endDate}</TableCell>
                  <TableCell>
                    {/* Only show action buttons if booking is not finalized */}
                    {booking.status !== EventBookingStatusEnum.Attended &&
                      booking.status !== EventBookingStatusEnum.NotAttended && (
                        <>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => handleStatusUpdateClick(booking.id, EventBookingStatusEnum.Attended)}
                            sx={{ mr: 1 }}
                          >
                            Attended
                          </Button>
                          <Button
                            variant="contained"
                            color="error"
                            size="small"
                            onClick={() => handleStatusUpdateClick(booking.id, EventBookingStatusEnum.NotAttended)}
                          >
                            Not Attended
                          </Button>
                        </>
                      )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>

      <Dialog open={confirmDialogOpen} onClose={handleDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>Update Booking Status</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to mark this booking as{' '}
            {newStatus === EventBookingStatusEnum.Attended ? 'Attended' : 'Not Attended'}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleConfirmStatusUpdate} disabled={updateLoading}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default OrganizerEventDetailPage;
