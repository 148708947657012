import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Paper, Avatar, IconButton, useTheme, useMediaQuery, Grid2 } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import TripsListContainer from '../components/Trips/TripsListContainer';
import HeroSection from '../components/LandingPage/HeroSection';
import { useTranslation } from 'react-i18next';
import { Parallax } from 'react-parallax';
import { CustomButton } from '../components/common/Button';

interface TeamMember {
  name: string;
  image: string;
  role?: string;
  linkedIn?: string;
  instagram?: string;
  twitter?: string;
  facebook?: string;
}

const teamMembers: TeamMember[] = [
  {
    name: 'Rasťo Kopál',
    image: '/images/members/rasto.jpg',
    role: 'Community Captain & Master shredder',
    instagram: 'https://www.instagram.com/_rastiik/',
  },
  {
    name: 'Jakub Fačkovec',
    image: '/images/members/jakub.jpg',
    role: 'Founder & Chief Wave Enjoyer',
    instagram: 'https://www.instagram.com/jakuposlav/',
  },
  {
    name: 'Verča Fačkovcová',
    image: '/images/members/veronika_h.png',
    role: 'Master Planner & Surf Safari Guru',
    instagram: 'https://www.instagram.com/veeercaa/',
  },
  {
    name: 'Roman Berešík',
    image: '/images/members/roman_b.png',
    role: 'Event Maestro & Wave Whisperer',
    instagram: 'https://www.instagram.com/romanberesik/',
    twitter: 'https://twitter.com/romanberesik',
    facebook: 'https://facebook.com/romanberesik',
  },
  {
    name: 'Tonka Rošková',
    image: '/images/members/antonia_r.jpg',
    role: 'Master planner & Longboard Queen',
    instagram: 'https://www.instagram.com/tonickaroskova/',
  },
  {
    name: 'Naďa Baničová',
    image: '/images/members/nada_b.png',
    role: 'Event Ninja & Fun Facilitator',
    instagram: 'https://www.instagram.com/wnadka_x/',
  },
  {
    name: 'Viliam Glemba',
    image: '/images/members/viliam_g.png',
    role: 'Marketing Maverick & Hype Generator',
    instagram: 'https://www.instagram.com/willwildtravel/',
  },
  {
    name: 'Martin Železník',
    image: '/images/members/martin_z.jpg',
    role: 'Social Media Wizard & Hashtag Hero',
    instagram: 'https://www.instagram.com/zeleznikmp/',
  },
];

const LandingPage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();

  const featureItems = [
    {
      img: '/images/landing-page/surf_girls.jpg',
      alt: 'Surfing Adventures',
      title: t('surfing_adventures'),
      text: t('surfing_adventures_text'),
    },
    {
      img: '/images/landing-page/people.jpeg',
      alt: 'Vibrant Community',
      title: t('vibrant_community'),
      text: t('vibrant_community_text'),
    },
    {
      img: '/images/landing-page/peniche.jpeg',
      alt: 'Explore Nature',
      title: t('explore_nature'),
      text: t('explore_nature_text'),
    },
  ];

  const cards = [
    { title: t('total_customers'), value: '150+' },
    { title: t('years_of_experience'), value: '2+' },
    { title: t('total_destinations'), value: '5+' },
    { title: t('average_rating'), value: '4.8' },
  ];

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : isMediumScreen ? 3 : 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: '0px',
  };

  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Calculate opacity and translateY based on scroll position
  const maxScroll = 300;
  const opacity = Math.max(1 - scrollY / maxScroll, 0);
  const translateY = Math.min(scrollY / 2, 150);

  return (
    <Box position="relative" sx={{ overflowX: 'hidden' }}>
      {/* Hero Section with Decoration Images */}
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <HeroSection />

        {/* Decoration Images */}
        {isLargeScreen && (
          <>
            {/* Left Decoration Image */}
            <Box
              component="img"
              src="/images/landing-page/left-bg.png"
              alt="Left Decoration"
              sx={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '14vw',
                opacity: opacity,
                transform: `translateY(${translateY}px)`,
                transition: 'opacity 0.3s, transform 0.3s',
                pointerEvents: 'none',
              }}
            />

            {/* Logo Image */}
            <Box
              component="img"
              src="/images/landing-page/logo_wihte_in.png"
              alt="Logo"
              sx={{
                position: 'absolute',
                left: { xs: '50%', md: '65%' },
                top: '40%',
                width: '15vw',
                opacity: opacity,
                transform: `translateY(${translateY}px) rotate(15deg)`,
                transition: 'opacity 0.3s, transform 0.3s',
                pointerEvents: 'none',
              }}
            />

            {/* Right Decoration Image */}
            <Box
              component="img"
              src="/images/landing-page/right-bg.png"
              alt="Right Decoration"
              sx={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '15vw',
                opacity: opacity,
                transform: `translateY(${translateY}px)`,
                transition: 'opacity 0.3s, transform 0.3s',
                pointerEvents: 'none',
              }}
            />
          </>
        )}
      </Box>

      {/* Active Trips Section */}
      <Box
        sx={{
          backgroundColor: theme.palette.grey[100],
          py: { xs: 4, sm: 6 },
        }}
        id="upcoming-trips"
      >
        <Container>
          <Typography variant="h4" gutterBottom align="center">
            {t('upcoming_trips')}
          </Typography>
          <TripsListContainer />
        </Container>
      </Box>

      {/* Highlights Section */}
      <Parallax bgImage="/images/landing-page/goyamboka-beach.jpeg" strength={200}>
        <Box
          sx={{
            py: { xs: 6, sm: 8, md: 20 },
          }}
        >
          <Container>
            <Grid2 container spacing={4}>
              {cards.map((card, index) => (
                <Grid2 size={{ xs: 12, sm: 6, md: 3 }} key={index}>
                  <Paper
                    elevation={1}
                    sx={{
                      p: { xs: 2, sm: 4 },
                      textAlign: 'center',
                      backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    }}
                  >
                    <Typography variant="h4" color="secondary" gutterBottom fontWeight="bold">
                      {card.value}
                    </Typography>
                    <Typography variant="subtitle1">{card.title}</Typography>
                  </Paper>
                </Grid2>
              ))}
            </Grid2>
          </Container>
        </Box>
      </Parallax>

      {/* About Us Section */}
      <Box
        sx={{
          position: 'relative',
          py: { xs: 6, sm: 8 },
          backgroundColor: theme.palette.grey[100],
          zIndex: 2,
          overflow: 'hidden',
          clipPath: 'polygon(0 5%, 100% 0%, 100% 95%, 0% 100%)',
          mt: -5,
          mb: -5,
        }}
      >
        <Container>
          <Grid2 container spacing={5} alignItems="center" direction={isMobile ? 'column-reverse' : 'row'}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Typography variant="h4" gutterBottom fontWeight="bold">
                {t('who_we_are')}
              </Typography>
              <Typography variant="body1" paragraph>
                {t('about_us_paragraph')}
              </Typography>
              <CustomButton
                text={t('learn_more')}
                href="/about"
                color="primary"
                sx={{ px: 4, py: 1.5, color: '#000', borderRadius: '24px' }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Box
                component="img"
                src="/images/landing-page/lanzarote-people.jpeg"
                alt="Surfing"
                sx={{
                  width: '100%',
                  boxShadow: theme.shadows[3],
                  borderRadius: 2,
                }}
              />
            </Grid2>
          </Grid2>
        </Container>
      </Box>

      {/* Features Section */}
      <Box
        sx={{
          py: { xs: 6, sm: 8 },
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Container>
          <Typography variant="h4" gutterBottom align="center" mt={1} fontWeight="bold">
            {t('why_join_us')}
          </Typography>
          <Grid2 container size={{ xs: 12 }} spacing={5}>
            {/* Feature Items */}
            {featureItems.map((feature, index) => (
              <Grid2 size={{ xs: 12, sm: 6, md: 4 }} key={index}>
                <Box sx={{ position: 'relative', textAlign: 'center' }}>
                  {/* Image */}
                  <Box
                    component="img"
                    src={feature.img}
                    alt={feature.alt}
                    sx={{
                      width: '100%',
                      height: { xs: '300px', sm: '350px', md: '450px' },
                      objectFit: 'cover',
                      boxShadow: theme.shadows[2],
                    }}
                  />
                  {/* Overlay */}
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(0, 0, 0, 0.4)',
                      color: '#fff',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                      alignItems: 'flex-start',
                      p: 2,
                    }}
                  >
                    <Typography
                      variant="body2"
                      align="left"
                      sx={{
                        fontSize: { xs: '0.8rem', sm: '0.9rem' },
                        color: '#D9D9D9',
                      }}
                    >
                      {feature.text}
                    </Typography>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      align="left"
                      sx={{
                        fontSize: { xs: '1rem', sm: '1.3rem' },
                      }}
                    >
                      {feature.title}
                    </Typography>
                  </Box>
                </Box>
              </Grid2>
            ))}
          </Grid2>
        </Container>
      </Box>

      {/* Testimonials Section */}
      <Parallax bgImage="/images/landing-page/male_beach.jpeg" strength={300}>
        <Box
          sx={{
            py: { xs: 6, sm: 8 },
          }}
        >
          <Container>
            <Typography variant="h4" gutterBottom align="center" fontWeight="bold" color={theme.palette.common.white}>
              {t('what_our_members_say')}
            </Typography>
            <Grid2 container spacing={5} sx={{ mt: 2 }}>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Paper
                  elevation={1}
                  sx={{
                    p: { xs: 2, sm: 4 },
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  }}
                >
                  <Typography variant="body1" paragraph>
                    {t('testimonial_rasto')}
                  </Typography>
                  <Typography variant="subtitle1" align="right">
                    {t('rasto')}
                  </Typography>
                </Paper>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Paper
                  elevation={1}
                  sx={{
                    p: { xs: 2, sm: 4 },
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  }}
                >
                  <Typography variant="body1" paragraph>
                    {t('testimonial_jakub')}
                  </Typography>
                  <Typography variant="subtitle1" align="right">
                    {t('jakub')}
                  </Typography>
                </Paper>
              </Grid2>
            </Grid2>
          </Container>
        </Box>
      </Parallax>

      {/* Team Section */}
      <Box
        sx={{
          py: { xs: 6, sm: 8 },
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Container>
          <Typography variant="h4" gutterBottom align="center" fontWeight="bold">
            {t('meet_our_team')}
          </Typography>

          {/* Carousel */}
          <Box sx={{ mt: 5, '& .slick-list': { overflow: 'hidden' } }}>
            <Slider {...sliderSettings}>
              {teamMembers.map((member) => (
                <Box
                  key={member.name}
                  sx={{
                    px: 2,
                    outline: 'none',
                  }}
                >
                  <Paper
                    elevation={1}
                    sx={{
                      p: { xs: 2, sm: 3 },
                      mb: 2,
                      textAlign: 'center',
                    }}
                  >
                    <Avatar
                      alt={member.name}
                      src={member.image}
                      sx={{
                        width: { xs: 80, sm: 120 },
                        height: { xs: 80, sm: 120 },
                        mx: 'auto',
                        mb: 2,
                      }}
                    />
                    <Typography variant="h6" fontWeight="bold">
                      {member.name}
                    </Typography>
                    {member.role && (
                      <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                        {member.role}
                      </Typography>
                    )}
                    {/* Social Icons */}
                    <Box sx={{ mt: 1 }}>
                      {member.instagram && (
                        <IconButton
                          component="a"
                          href={member.instagram}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="Instagram"
                          sx={{ color: '#E4405F' }}
                        >
                          <InstagramIcon />
                        </IconButton>
                      )}
                      {member.twitter && (
                        <IconButton
                          component="a"
                          href={member.twitter}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="Twitter"
                          sx={{ color: '#1DA1F2' }}
                        >
                          <TwitterIcon />
                        </IconButton>
                      )}
                      {member.facebook && (
                        <IconButton
                          component="a"
                          href={member.facebook}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="Facebook"
                          sx={{ color: '#1877F2' }}
                        >
                          <FacebookIcon />
                        </IconButton>
                      )}
                    </Box>
                  </Paper>
                </Box>
              ))}
            </Slider>
          </Box>
        </Container>
      </Box>

      {/* Call to Action */}
      <Box
        sx={{
          py: { xs: 6, sm: 8 },
          backgroundImage: 'url("/images/loyalty/goyamboka-beach.jpeg")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          color: '#fff',
          textAlign: 'center',
        }}
      >
        {/* Overlay */}
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.6)',
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        ></Box>
        <Container sx={{ position: 'relative', zIndex: 2 }}>
          <Typography variant="h4" gutterBottom fontWeight="bold" sx={{ textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}>
            {t('ready_to_start_your_adventure')}
          </Typography>
          <Typography variant="body1" paragraph sx={{ textShadow: '1px 1px 2px rgba(0,0,0,0.5)' }}>
            {t('join_us_on_next_trip')}
          </Typography>
          <CustomButton
            text={t('join_now')}
            href="/loyalty"
            color="primary"
            sx={{ px: 4, py: 1.5, color: 'black', borderRadius: '24px' }}
          />
        </Container>
      </Box>
    </Box>
  );
};

export default LandingPage;
