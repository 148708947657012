import React, { useState, useMemo } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { USERS_QUERY } from '../../graphql/users';
import { User } from '../../generated/graphql';

interface StaffSelectProps {
  selectedStaff: User[];
  onChange: (newStaff: User[]) => void;
}

const StaffSelect: React.FC<StaffSelectProps> = ({ selectedStaff, onChange }) => {
  const [options, setOptions] = useState<User[]>([]);
  const [fetchUsers, { loading }] = useLazyQuery(USERS_QUERY, {
    onCompleted: (data) => {
      if (data?.users?.edges) {
        const newOptions = data.users.edges.map(({ node }: { node: User }) => node);
        setOptions(newOptions);
      }
    },
  });

  // Debounce the fetch to avoid too many API calls.
  const debouncedFetch = useMemo(() => {
    return (query: string) => {
      fetchUsers({ variables: { filter: { query } } });
    };
  }, [fetchUsers]);

  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={(option: User) => `${option.firstname || ''} ${option.lastname || ''}`.trim()}
      value={selectedStaff}
      onChange={(_event, newValue) => onChange(newValue)}
      onInputChange={(_event, value) => {
        if (value) {
          debouncedFetch(value);
        } else {
          setOptions([]);
        }
      }}
      loading={loading}
      renderInput={(params) => <TextField {...params} label="Search Staff" variant="outlined" />}
    />
  );
};

export default StaffSelect;
