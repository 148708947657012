import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress,
  Alert,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import { Link as RouterLink } from 'react-router-dom';
import {
  Trip,
  TripOrder,
  TripStatusEnum,
  Role,
  SubmitInitiatedTripMutation,
  SubmitInitiatedTripMutationVariables,
} from '../../generated/graphql';
import { useAuth } from '../../contexts/AuthContext';
import { SUBMIT_INITIATED_TRIP_MUTATION, TRIPS_QUERY } from '../../graphql/trips';
import { useMutation, useQuery } from '@apollo/client';

const OrganizerDashboardPage: React.FC = () => {
  const { user } = useAuth();
  const [statusFilter, setStatusFilter] = useState<TripStatusEnum | null>(TripStatusEnum.Pending);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');

  const [selectedTrip, setSelectedTrip] = useState<Trip | null>(null);
  const [actionsAnchorEl, setActionsAnchorEl] = useState<null | HTMLElement>(null);

  const [confirmAction, setConfirmAction] = useState<'pending' | 'approve' | 'cancel' | 'reopen'>('approve');
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);

  const {
    data: tripsData,
    loading: tripsLoading,
    error: tripsError,
    refetch,
  } = useQuery(TRIPS_QUERY, {
    variables: {
      first: rowsPerPage,
      skip: page * rowsPerPage,
      orderBy: { field: 'createdAt', direction: 'desc' } as TripOrder,
      filter: {
        status: statusFilter,
        organizerId: user?.id,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const [submitInitiatedTrip] = useMutation<SubmitInitiatedTripMutation, SubmitInitiatedTripMutationVariables>(
    SUBMIT_INITIATED_TRIP_MUTATION
  );

  const handleConfirmAction = async () => {
    if (!selectedTrip) return;
    try {
      if (confirmAction === 'pending') {
        await submitInitiatedTrip({
          variables: { id: selectedTrip.id },
        });
        setSnackbarMessage('Trip status set to pending.');
      }
      setOpenSnackbar(true);
      refetch();
    } catch (error) {
      console.error(error);
      setSnackbarMessage('An error occurred. Please try again.');
      setOpenSnackbar(true);
    }
    handleCloseConfirmDialog();
  };

  // Guard
  if (!user?.roles.includes(Role.Organizer)) {
    return <Alert severity="error">You do not have permission to access this page.</Alert>;
  }

  // Handle Status Filter
  const handleStatusFilterChange = (event: React.MouseEvent<HTMLElement>, newStatus: TripStatusEnum | null) => {
    setStatusFilter(newStatus);
    setPage(0);
  };

  // Handle Actions
  const handleActionsMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, trip: Trip) => {
    setActionsAnchorEl(event.currentTarget);
    setSelectedTrip(trip);
  };
  const handleActionsMenuClose = () => {
    setActionsAnchorEl(null);
  };

  const handleCloseConfirmDialog = () => {
    setIsConfirmDialogOpen(false);
  };

  // Confirm Dialog
  const handleOpenConfirmDialog = (action: 'pending' | 'approve' | 'cancel' | 'reopen') => {
    setConfirmAction(action);
    setIsConfirmDialogOpen(true);
    handleActionsMenuClose();
  };

  // Pagination
  const handleChangePage = (event: unknown, newPage: number) => setPage(newPage);
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  if (tripsLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }
  if (tripsError) {
    console.error(tripsError);
    return <Alert severity="error">Error loading trips.</Alert>;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">My Trips</Typography>
        <Button variant="contained" startIcon={<AddIcon />} component={RouterLink} to={'/trip'}>
          Create New Trip
        </Button>
      </Box>

      {/* Status Filter */}
      <Box mb={2}>
        <ToggleButtonGroup
          value={statusFilter}
          exclusive
          onChange={handleStatusFilterChange}
          aria-label="trip status filter"
        >
          <ToggleButton value={TripStatusEnum.Initiated} aria-label="pending trips">
            Initiated
          </ToggleButton>
          <ToggleButton value={TripStatusEnum.Pending} aria-label="pending trips">
            Pending
          </ToggleButton>
          <ToggleButton value={TripStatusEnum.Active} aria-label="active trips">
            Active
          </ToggleButton>
          <ToggleButton value={TripStatusEnum.Cancelled} aria-label="cancelled trips">
            Cancelled
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Price (€)</TableCell>
              <TableCell>Country</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {tripsData?.trips?.edges.map(({ node }: { node: Trip }) => (
              <TableRow key={node.id}>
                <TableCell>
                  <Button component={RouterLink} to={`/trip/${node.id}`} variant="text" color="primary">
                    {node.title}
                  </Button>
                </TableCell>
                <TableCell>{node.status}</TableCell>
                <TableCell>{new Date(node.startDate).toLocaleDateString()}</TableCell>
                <TableCell>{new Date(node.endDate).toLocaleDateString()}</TableCell>
                <TableCell>€{node.price.toLocaleString()}</TableCell>
                <TableCell>{node.country}</TableCell>
                <TableCell align="center">
                  <IconButton onClick={(e) => handleActionsMenuOpen(e, node)} aria-label="actions">
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {/* Pagination */}
        <TablePagination
          component="div"
          count={tripsData?.trips?.totalCount || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </TableContainer>

      {/* Organizer "Actions" Menu */}
      <Menu anchorEl={actionsAnchorEl} open={Boolean(actionsAnchorEl)} onClose={handleActionsMenuClose}>
        {/* For example, always show "View Organizer Details" */}
        <MenuItem component={RouterLink} to={`/organizer/trip/${selectedTrip?.id}`} onClick={handleActionsMenuClose}>
          View Organizer Details
        </MenuItem>

        {/* Possibly only show "Edit" if trip is still in Pending */}
        {selectedTrip?.status === TripStatusEnum.Initiated && (
          <MenuItem component={RouterLink} to={`/trip/${selectedTrip?.id}/edit`} onClick={handleActionsMenuClose}>
            Edit Trip
          </MenuItem>
        )}
        {selectedTrip?.status === TripStatusEnum.Initiated && (
          <MenuItem onClick={() => handleOpenConfirmDialog('pending')}>Set to Pending</MenuItem>
        )}
      </Menu>

      {/* Confirm Dialog */}
      <Dialog open={isConfirmDialogOpen} onClose={handleCloseConfirmDialog}>
        <DialogTitle>
          {confirmAction === 'approve'
            ? 'Approve Trip'
            : confirmAction === 'cancel'
              ? 'Cancel Trip'
              : 'Set Trip to Pending'}
        </DialogTitle>
        <DialogContent>
          Are you sure you want to{' '}
          {confirmAction === 'approve' ? 'approve' : confirmAction === 'cancel' ? 'cancel' : 'set to pending'} this
          trip?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmAction} color="secondary">
            {confirmAction === 'approve' ? 'Approve' : confirmAction === 'cancel' ? 'Cancel Trip' : 'Set to Pending'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={openSnackbar} autoHideDuration={4000} onClose={handleSnackbarClose} message={snackbarMessage} />
    </Container>
  );
};

export default OrganizerDashboardPage;
