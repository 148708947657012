import React from 'react';
import { Container, Typography, Box, CircularProgress, Alert } from '@mui/material';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { CREATE_EVENT_MUTATION, EVENT_QUERY } from '../../graphql/events';
import { CreateEventInput } from '../../generated/graphql';
import EventForm from '../../components/Events/EventForm';

const CreateEventPage: React.FC = () => {
  const navigate = useNavigate();
  const [createEvent, { loading, error }] = useMutation(CREATE_EVENT_MUTATION, {
    refetchQueries: [{ query: EVENT_QUERY }],
  });

  // Initial values now include the new address fields.
  const initialValues: CreateEventInput = {
    title: '',
    description: '',
    location: '',
    country: '',
    state: '',
    city: '',
    zipCode: '',
    streetAddress: '',
    timezone: 'Europe/Bratislava',
    staffIds: [],
    arrivalLocation: '',
    howToGetThere: '',
    startDate: new Date(),
    endDate: new Date(),
    capacity: 1,
    price: 0,
    images: [],
    tags: [],
  };

  const handleSubmit = async (values: CreateEventInput) => {
    const payload = { ...values };
    const response = await createEvent({ variables: { input: payload } });
    const eventId = response.data.createEvent.id;
    navigate(`/events/${eventId}`);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Create New Event
      </Typography>
      {error && <Alert severity="error">{error.message}</Alert>}
      {loading ? (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <EventForm initialValues={initialValues} onSubmit={handleSubmit} isSubmitting={loading} />
      )}
    </Container>
  );
};

export default CreateEventPage;
