import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface ImageUploadEventProps {
  onUploadSuccess: (imageUrl: string) => void;
}

const ImageUploadEvent: React.FC<ImageUploadEventProps> = ({ onUploadSuccess }) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // When files are selected, update the state and create preview URLs.
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);
    const files = event.target.files;
    if (files) {
      const fileArray = Array.from(files);
      const validFiles: File[] = [];
      const previews: string[] = [];

      fileArray.forEach((file) => {
        // Validate file type
        if (!file.type.match(/image\/(jpg|jpeg|png|gif|webp|heic|heif)/)) {
          setError('Only image files (jpg, jpeg, png, gif, webp, heic, heif) are allowed.');
          return;
        }
        // Validate file size (< 5MB)
        if (file.size > 5 * 1024 * 1024) {
          setError('File size must be less than 5MB.');
          return;
        }
        validFiles.push(file);
        // Create a preview URL using URL.createObjectURL
        previews.push(URL.createObjectURL(file));
      });

      setSelectedFiles(validFiles);
      setPreviewUrls(previews);
    }
  };

  // Upload each file to the backend.
  const handleUpload = async () => {
    if (!selectedFiles.length) {
      setError('No file selected');
      return;
    }
    setUploading(true);
    setError(null);

    try {
      for (const file of selectedFiles) {
        const formData = new FormData();
        formData.append('file', file);

        // Retrieve the access token from localStorage (if needed)
        const accessToken = localStorage.getItem('accessToken');

        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/upload/image`, {
          method: 'POST',
          headers: {
            // Note: Do not set 'Content-Type' for FormData requests.
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Failed to upload image');
        }

        const data = await response.json();
        // Call the parent callback with the uploaded image URL
        onUploadSuccess(data.imageUrl);
      }
      // Clear selected files and previews after successful upload.
      setSelectedFiles([]);
      setPreviewUrls([]);
    } catch (err: any) {
      console.error(err);
      setError('Failed to upload image');
    } finally {
      setUploading(false);
    }
  };

  // Delete an image from the local selection.
  const handleDeleteImage = (index: number) => {
    const newFiles = selectedFiles.filter((_, i) => i !== index);
    const newPreviews = previewUrls.filter((_, i) => i !== index);
    setSelectedFiles(newFiles);
    setPreviewUrls(newPreviews);
  };

  // Cleanup preview URLs when component unmounts.
  useEffect(() => {
    return () => {
      previewUrls.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [previewUrls]);

  return (
    <Box>
      <input
        type="file"
        accept="image/*"
        multiple
        style={{ display: 'none' }}
        id="event-image-upload"
        onChange={handleFileChange}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <label htmlFor="event-image-upload">
          <Button variant="outlined" component="span">
            Choose Images
          </Button>
        </label>
        {selectedFiles.length > 0 && (
          <Button variant="contained" onClick={handleUpload} disabled={uploading}>
            {uploading ? 'Uploading...' : 'Upload Images'}
          </Button>
        )}
      </Box>

      {uploading && (
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={24} />
        </Box>
      )}

      {error && (
        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}

      <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        {previewUrls.map((url, index) => (
          <Box key={index} sx={{ position: 'relative' }}>
            <Box
              component="img"
              src={url}
              alt={`Preview ${index}`}
              sx={{ height: 100, width: 100, objectFit: 'cover', borderRadius: 1 }}
            />
            <IconButton
              onClick={() => handleDeleteImage(index)}
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                bgcolor: 'rgba(0,0,0,0.6)',
                color: 'white',
                '&:hover': { bgcolor: 'rgba(0,0,0,0.8)' },
              }}
              size="small"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ImageUploadEvent;
