import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { client } from './utils/apollo';
import { theme } from './styles/theme';
import MembershipRequestPage from './pages/Membership/MembershipRequestPage';
import LandingPage from './pages/LandingPage';
import MembershipDashboardPage from './pages/Membership/MembershipDashboardPage';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import ProtectedRoute from './components/ProtectedRoute';
import Header from './components/Header';
import MePage from './pages/User/MePage';
import UserManagementPage from './pages/Admin/UserManagementPage';
import MembershipDetailPage from './pages/Membership/MembershipDetailPage';
import UserPage from './pages/User/UserPage';
import GoogleCallback from './pages/Auth/GoogleCallback';
import AuthPage from './pages/Auth/AuthPage';
import { AuthProvider } from './contexts/AuthContext';
import TripManagementPage from './pages/Admin/TripManagementPage';
import TripDetailPage from './pages/Trip/TripDetailPage';
import TripEditPage from './pages/Admin/TripEditPage';
import LoyaltyProgramPage from './pages/LoyaltyProgram/LoyaltyProgramPage';

import './fonts.scss';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './i18n';
import './styles/globalStyles.scss';
import EmailVerificationPage from './pages/Auth/EmailVerificationPage';
import ResetPasswordPage from './pages/Auth/ResetPasswordPage';
import ForgotPasswordPage from './pages/Auth/ForgotPasswordPage';
import OrganizerDashboardPage from './pages/Organizer/OrganizerDashboardPage';
import OrganizerTripManagementPage from './pages/Organizer/OrganizerTripManagementPage';
import TripReviewPage from './pages/Trip/TripReviewPage';
import { Role } from './generated/graphql';
import CreateEventPage from './pages/Events/CreateEventPage';
import EditEventPage from './pages/Events/EditEventPage';
import EventDetailPage from './pages/Events/EventDetailPage';
import EventsPage from './pages/Events/EventsPage';
import OrganizerEventManagementPage from './pages/Events/OrganizerEventManagementPage';
import OrganizerEventDetailPage from './pages/Events/OrganizerEventDetailPage';

function App() {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <AuthProvider>
            <Header />
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/auth" element={<AuthPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
              <Route path="/auth/callback" element={<GoogleCallback />} />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <MePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/trip"
                element={
                  <ProtectedRoute allowedRoles={[Role.Organizer, Role.Admin]}>
                    <TripEditPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/trip/:tripId" element={<TripDetailPage />} />
              <Route
                path="/trip/:tripId/edit"
                element={
                  <ProtectedRoute>
                    <TripEditPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/organizer/trip/:tripId"
                element={
                  <ProtectedRoute allowedRoles={[Role.Organizer, Role.Admin]}>
                    <OrganizerTripManagementPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/user/:userId"
                element={
                  <ProtectedRoute>
                    <UserPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/membership/:userId" element={<MembershipDetailPage />} />
              <Route
                path="/membership-request"
                element={
                  <ProtectedRoute>
                    <MembershipRequestPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/membership-dashboard"
                element={
                  <ProtectedRoute>
                    <MembershipDashboardPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="organizer/trips-management"
                element={
                  <ProtectedRoute>
                    <OrganizerDashboardPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/verify-email" element={<EmailVerificationPage />} />
              <Route
                path="/admin/user-management"
                element={
                  <ProtectedRoute allowedRoles={[Role.Admin]}>
                    <UserManagementPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/trips-management"
                element={
                  <ProtectedRoute allowedRoles={[Role.Admin]}>
                    <TripManagementPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/events/create"
                element={
                  <ProtectedRoute allowedRoles={[Role.Admin, Role.EventOrganizer]}>
                    <CreateEventPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/events/:eventId/edit"
                element={
                  <ProtectedRoute allowedRoles={[Role.Admin, Role.EventOrganizer]}>
                    <EditEventPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/organizer/events-management"
                element={
                  <ProtectedRoute allowedRoles={[Role.Admin, Role.EventOrganizer]}>
                    <OrganizerEventManagementPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/events/:eventId/organizer-detail"
                element={
                  <ProtectedRoute allowedRoles={[Role.Admin, Role.EventOrganizer]}>
                    <OrganizerEventDetailPage />
                  </ProtectedRoute>
                }
              />

              <Route path="/events" element={<EventsPage />} />
              <Route path="/events/:eventId" element={<EventDetailPage />} />
              <Route path="/loyalty" element={<LoyaltyProgramPage />} />
              <Route path="/trip/:tripId/review" element={<TripReviewPage />} />
            </Routes>
          </AuthProvider>
        </Router>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
