import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Avatar,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  Button,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CloseIcon from '@mui/icons-material/Close';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import { useTranslation } from 'react-i18next';

import { User } from '../../../generated/graphql';
import { getNextLevelExp } from '../utils';
import { loyaltyLevels, LoyaltyLevelToName } from '../../../shared/loyalty/ProgramData';

import MyTripsSection from './MyTripsSection/MyTripsSection';

import { useMutation } from '@apollo/client';
import { CANCEL_MY_MEMBERSHIP_MUTATION } from '../../../graphql/membership';
import MyUpcomingEventsSection from '../../Events/MyUpcomingEventsSection';

interface ProfileDashboardProps {
  user: User;
}

// Helper to pick a color for the user's level badge
const getBadgeColor = (level: string) => {
  switch (level) {
    case 'Level_1':
      return '#CD7F32'; // Bronze
    case 'Level_2':
      return '#C0C0C0'; // Silver
    case 'Level_3':
      return '#FFD700'; // Gold
    default:
      return '#808080'; // Gray
  }
};

const ProfileDashboard: React.FC<ProfileDashboardProps> = ({ user }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // membership cancellation logic
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [cancelMembership, { loading: cancelLoading }] = useMutation(CANCEL_MY_MEMBERSHIP_MUTATION, {
    refetchQueries: ['myMembership'],
    onError: (err) => {
      console.error('Cancel membership error:', err);
    },
  });

  // user's loyalty stats
  const loyaltyLevel = user?.level ?? 'LEVEL_0';
  const loyaltyPoints = user?.exp ?? 0;
  const nextLevelExp = getNextLevelExp(loyaltyLevel);
  const progressToNextLevel = nextLevelExp > 0 ? (loyaltyPoints / nextLevelExp) * 100 : 100;

  // membership data
  const membership = user.membership;
  const membershipId = membership?.id;
  const membershipCreatedAt = membership ? new Date(membership.createdAt).toLocaleDateString() : '';

  // handle membership cancel
  const handleOpenCancelModal = () => setCancelModalOpen(true);
  const handleCloseCancelModal = () => setCancelModalOpen(false);
  const handleConfirmCancel = async () => {
    try {
      await cancelMembership();
      setCancelModalOpen(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      {/* Top Section: Avatar + Basic Name */}
      <Box sx={{ textAlign: 'center', mb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar
          src={user?.imageUrl || '/default-avatar.png'}
          sx={{
            width: isMobile ? 80 : 100,
            height: isMobile ? 80 : 100,
            mb: 1.5,
          }}
        />
        <Typography variant={isMobile ? 'h6' : 'h5'} gutterBottom>
          {t('welcome')}, {user?.firstname ?? t('guest')}!
        </Typography>
      </Box>

      {/* Row: membership info + loyalty info */}
      <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 2, mb: 4 }}>
        {/* Membership Info */}
        <Card sx={{ flex: 1, borderRadius: 3, mb: isMobile ? 2 : 0 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {t('membership_information_title')}
            </Typography>
            <Typography variant="body1">
              {t('status')}: {t('active')} {/* Hard-coded "active" for now */}
            </Typography>
            <Typography variant="body1">
              {t('member_id')}: {membershipId}
            </Typography>
            <Typography variant="body1">
              {t('member_since')}: {membershipCreatedAt}
            </Typography>

            <Button variant="outlined" color="error" fullWidth onClick={handleOpenCancelModal} sx={{ mt: 2 }}>
              {t('cancel_membership_button')}
            </Button>
          </CardContent>
        </Card>

        {/* Loyalty Info */}
        <Card sx={{ flex: 1, borderRadius: 3 }}>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <Box
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: '50%',
                  border: `3px solid ${getBadgeColor(loyaltyLevel)}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 1,
                }}
              >
                <Box
                  component="img"
                  src={loyaltyLevels.find((lvl) => lvl.title === loyaltyLevel.toString())?.icon}
                  alt={`Level ${loyaltyLevel} badge`}
                  sx={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    objectFit: 'cover',
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <LoyaltyIcon color="primary" sx={{ mr: 1 }} />
              <Typography variant="h6">{t('loyalty_program')}</Typography>
            </Box>
            <Typography variant="body1">
              {t('level')}: <strong>{LoyaltyLevelToName[loyaltyLevel]}</strong>
            </Typography>
            <Typography variant="body1">
              {t('experience_points')}: <strong>{loyaltyPoints}</strong>
            </Typography>
            {nextLevelExp > 0 && (
              <>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  {t('progress_to_next_level')}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={progressToNextLevel}
                  sx={{ height: 10, borderRadius: 5, mt: 1 }}
                />
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  {loyaltyPoints} / {nextLevelExp} EXP
                </Typography>
              </>
            )}
            {nextLevelExp === 0 && (
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                {t('highest_level_reached')}
              </Typography>
            )}
            <Button variant="outlined" color="primary" fullWidth href="/loyalty" sx={{ mt: 2 }}>
              {t('learn_more')}
            </Button>
          </CardContent>
        </Card>
      </Box>

      {/* My Trips Section */}
      <MyTripsSection />

      <MyUpcomingEventsSection />

      {/* Cancel membership dialog */}
      <Dialog
        open={cancelModalOpen}
        onClose={handleCloseCancelModal}
        fullWidth
        maxWidth="sm"
        PaperProps={{ sx: { borderRadius: 3 } }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            fontWeight: 'bold',
            borderBottom: `1px solid ${theme.palette.divider}`,
            pb: 1,
          }}
        >
          <WarningAmberIcon color="warning" />
          {t('confirm_cancellation_title')}
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleCloseCancelModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" paragraph>
            {t('cancel_membership_warning')}
          </Typography>
          <Typography variant="body1" color="error" paragraph>
            {t('are_you_sure_cancel')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancelModal} disabled={cancelLoading}>
            {t('no_keep_membership')}
          </Button>
          <Button variant="contained" color="error" onClick={handleConfirmCancel} disabled={cancelLoading}>
            {cancelLoading ? t('cancelling') : t('yes_cancel_now')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ProfileDashboard;
