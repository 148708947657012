import React from 'react';
import {
  Container,
  Typography,
  Box,
  Grid2 as Grid,
  Card,
  CardContent,
  CardMedia,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { useTranslation } from 'react-i18next';
import { earnExpMethods, loyaltyLevels, LoyaltyLevelToName } from '../../shared/loyalty/ProgramData';
import { LoyaltyLevel } from '../../generated/graphql';
import { ExperienceCard } from './ExperienceCard';
import ParallaxSection from './ParallaxSection';
const LoyaltyProgramPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <ParallaxSection />

      <Container maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
        {/* How to Earn EXP */}
        <Box sx={{ mb: 10 }}>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
            {t('how_to_earn_exp_title')}
          </Typography>
          <Grid container spacing={6} sx={{ mt: 4 }}>
            {earnExpMethods.map((method, index) => (
              <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
                <ExperienceCard method={method} key={index} />
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Membership Levels */}
        <Box mb={10}>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
            {t('membership_levels_title')}
          </Typography>
          <Grid container spacing={6} sx={{ mt: 4 }}>
            {loyaltyLevels.map((level, index) => (
              <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
                <Card sx={{ height: '100%', borderRadius: '20px' }}>
                  <CardMedia
                    component="img"
                    height="220"
                    image={level.icon}
                    alt={level.title}
                    sx={{
                      borderTopLeftRadius: '20px',
                      borderTopRightRadius: '20px',
                    }}
                  />
                  <CardContent>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                      {LoyaltyLevelToName[level.title as LoyaltyLevel]}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'center' }}>
                      {t('exp_range')}: {level.exp}
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <List dense>
                      {level.benefits.map((benefit, idx) => (
                        <ListItem key={idx}>
                          <ListItemIcon>
                            <StarIcon color="secondary" />
                          </ListItemIcon>
                          <ListItemText primary={benefit} />
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default LoyaltyProgramPage;
