import React from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Badge,
  Skeleton,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { ChevronLeft, ChevronRight, LocationOn, PeopleAlt, FavoriteBorder } from '@mui/icons-material';
import { DateTime } from 'luxon';
import { Event } from '../../generated/graphql';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface EventsCalendarProps {
  events: Event[] | null | undefined;
  isLoading: boolean;
  currentDate: DateTime;
  numberOfDays: number;
  onNavigateWeek: (direction: 'prev' | 'next' | 'today') => void;
}

const EventsCalendar: React.FC<EventsCalendarProps> = ({
  events,
  isLoading,
  currentDate,
  numberOfDays,
  onNavigateWeek,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Create an array of days based on the current date and numberOfDays.
  const days: DateTime[] = React.useMemo(() => {
    return Array.from({ length: numberOfDays }, (_, i) => currentDate.plus({ days: i }));
  }, [currentDate, numberOfDays]);

  if (!events) {
    return null;
  }

  // Filter and sort events for a given day.
  const getEventsByDay = (day: DateTime): Event[] => {
    return events
      .filter((event) => {
        const eventDate = DateTime.fromISO(event.startDate);
        return eventDate.hasSame(day, 'day');
      })
      .sort((a, b) => {
        const aTime = DateTime.fromISO(a.startDate);
        const bTime = DateTime.fromISO(b.startDate);
        return aTime < bTime ? -1 : 1;
      });
  };

  // Navigate to the event detail page.
  const onSelectEvent = (eventId: string) => {
    navigate(`/events/${eventId}`);
  };

  // Render the event title with responsive font sizes.
  const getTitleTypography = (title: string) => (
    <Typography variant="h6" noWrap sx={{ fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' } }}>
      {title}
    </Typography>
  );

  // Render a single event card.
  const EventCard: React.FC<{ event: Event }> = ({ event }) => {
    const startTime = DateTime.fromISO(event.startDate).toFormat('h:mm a');
    const endTime = DateTime.fromISO(event.endDate).toFormat('h:mm a');
    const occupancy = `${event.totalBookings}/${event.capacity}`;

    // Use the first image as a background (if available) with a dark overlay.
    const backgroundImage =
      event.images && event.images.length > 0
        ? `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${event.images[0]})`
        : 'none';

    return (
      <Card
        variant="outlined"
        sx={{
          mb: 1,
          transition: 'transform 0.2s',
          '&:hover': { transform: 'scale(1.02)' },
          backgroundImage,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: event.images && event.images.length > 0 ? 'white' : 'inherit',
        }}
      >
        <CardActionArea onClick={() => onSelectEvent(event.id)}>
          <CardContent>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              {event.tags && event.tags.length > 0 && (
                <Badge color="primary" badgeContent={event.tags[0]} sx={{ mr: 1 }} />
              )}
              {getTitleTypography(event.title)}
            </Box>
            <Typography variant="body2" sx={{ mt: 1 }}>
              {startTime} – {endTime}
            </Typography>
            <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
              <LocationOn fontSize="small" sx={{ mr: 0.5 }} />
              <Typography variant="body2">{event.location}</Typography>
            </Box>
            <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
              <PeopleAlt fontSize="small" sx={{ mr: 0.5 }} />
              <Typography variant="body2">{occupancy} spots</Typography>
            </Box>
          </CardContent>
        </CardActionArea>
        <CardActions sx={{ justifyContent: 'flex-end', p: 1 }}>
          <Button size="small" color="primary" startIcon={<FavoriteBorder />} onClick={() => onSelectEvent(event.id)}>
            Book Now
          </Button>
        </CardActions>
      </Card>
    );
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      {/* Header */}
      <Box textAlign="center" mb={4}>
        <Typography variant={isMobile ? 'h4' : 'h3'} fontWeight="bold">
          {t('local_events_title')}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" mt={1}>
          {t('local_events_subtitle')}
        </Typography>
      </Box>

      {/* Navigation Buttons */}
      <Box display="flex" justifyContent="center" gap={2} mb={3}>
        <Button variant="outlined" onClick={() => onNavigateWeek('prev')}>
          <ChevronLeft fontSize="small" /> {t('previous')}
        </Button>
        <Button variant="outlined" onClick={() => onNavigateWeek('today')}>
          {t('today')}
        </Button>
        <Button variant="outlined" onClick={() => onNavigateWeek('next')}>
          {t('next')} <ChevronRight fontSize="small" />
        </Button>
      </Box>

      {/* Dynamic Grid – exactly numberOfDays columns */}
      <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: `repeat(${numberOfDays}, 1fr)` }}>
        {days.map((day) => (
          <Box key={day.toISO()} sx={{ p: 2, borderRadius: 2, boxShadow: 1, backgroundColor: 'white' }}>
            <Box sx={{ textAlign: 'center', mb: 1 }}>
              <Typography variant="subtitle2" color="textSecondary">
                {day.toFormat('ccc')}
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                color={day.hasSame(DateTime.now(), 'day') ? 'primary.main' : 'textPrimary'}
              >
                {day.toFormat('d')}
              </Typography>
            </Box>
            <Box>
              {isLoading ? (
                <>
                  <Skeleton variant="rectangular" height={80} sx={{ mb: 1 }} />
                  <Skeleton variant="rectangular" height={80} />
                </>
              ) : (
                <>
                  {getEventsByDay(day).length > 0 ? (
                    getEventsByDay(day).map((evt) => <EventCard key={evt.id} event={evt} />)
                  ) : (
                    <Typography variant="body2" color="textSecondary" align="center">
                      No events scheduled
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default EventsCalendar;
